import React from 'react';
import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

const generateRandomColor = () => {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
};

const JobDepartmentDoughnutChart = ({ data, colors = [], classes }) => {
  const departmentJobCounts = data.allGreenhouseJob.edges.reduce(
    (acc, jobEdge) => {
      jobEdge.node.departments.forEach(department => {
        acc[department.name] = (acc[department.name] || 0) + 1;
      });
      return acc;
    },
    {}
  );

  const backgroundColors = Object.keys(departmentJobCounts).map(
    (department, index) => colors[index] || generateRandomColor()
  );

  const chartData = {
    labels: Object.keys(departmentJobCounts),
    datasets: [
      {
        data: Object.values(departmentJobCounts),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Box className={classes.chartContainer} mb={10}>
      <Box mb={5}>
        <Typography
          component="h2"
          variant="h2"
          align="center"
          className={classes.title}
        >
          Where are our open roles?
        </Typography>
      </Box>
      <div style={{ height: '500px' }}>
        <Doughnut data={chartData} options={chartOptions} />
      </div>
      <div className={classes.legend}>
        {backgroundColors.map((color, index) => (
          <div key={color} className={classes.legendItem}>
            <span
              style={{ backgroundColor: color }}
              className={classes.legendColor}
            ></span>
            {chartData.labels[index]}
          </div>
        ))}
      </div>
    </Box>
  );
};

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
  chartContainer: {
    textAlign: 'center',
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  legendItem: {
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  legendColor: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
});

export default withStyles(styles)(JobDepartmentDoughnutChart);
