import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from '@material-ui/core/Box';
import HeroSecondaryPage from 'components/hero/HeroSecondaryPage';
import JobListing from 'components/joblisting';
import { withPreview } from 'gatsby-source-prismic';

import Container from '@material-ui/core/Container';

import JobDepartmentDoughnutChart from 'components/charts/JobDepartmentDoughnutChart';
import JobDepartmentRadarChart from 'components/charts/JobDepartmentRadarChart';
import JobDepartmentPieChart from 'components/charts/JobDepartmentPieChart';

const Opportunities = props => {
  const { data, location } = props;
  return (
    <Layout headerOpacity="0.9">
      <Helmet>
        <title>OVO Careers | Open Job Opportunities</title>
      </Helmet>
      <Box component="div">
        <HeroSecondaryPage
          heroTitleRichText={data.prismicJobListings.data.banner_title.raw}
          heroTitle={data.prismicJobListings.data.banner_title.text}
          heroImage={data.prismicJobListings.data.banner_image.url}
          heroContent={
            data.prismicJobListings.data.banner_content
              ? data.prismicJobListings.data.banner_content.raw
              : ''
          }
        />
        <JobListing data={data} location={location} />
      </Box>
    </Layout>
  );
};

export default withPreview(Opportunities);

Opportunities.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  {
    prismicJobListings {
      data {
        banner_title {
          text
          raw
        }
        banner_image {
          url
        }
        banner_content {
          raw
        }
      }
    }
    allGreenhouseJob(filter: { gh_Id: { ne: 3427618 } }) {
      totalCount
      edges {
        node {
          title
          internal_job_id
          gh_Id
          location {
            name
          }
          offices {
            name
            gh_Id
            fields {
              slug
            }
          }
          departments {
            gh_Id
            name
            fields {
              slug
            }
          }
          fields {
            slug
          }
          metadata {
            name
          }
        }
      }
    }
    allGreenhouseDepartment {
      edges {
        node {
          name
          gh_Id
          jobs {
            title
          }
        }
      }
    }
    allGreenhouseOffice {
      edges {
        node {
          name
          gh_Id
        }
      }
    }
    opportunitiesJson {
      heroTitle
      heroImage {
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
